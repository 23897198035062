<template>
    <v-card>
        <v-card-title primary-title>
            <v-icon left large>mdi-shopping</v-icon><span class="headline">Surtir pedidos</span>
            <v-spacer></v-spacer>
          <v-btn v-if="canPrint" color="primary" depressed @click="printEtiquetas()" icon><v-icon>mdi-printer</v-icon></v-btn>
            
        </v-card-title>
        <v-divider></v-divider>
        
        <v-card-text>
            <v-row>
            <v-col  class="pb-0 pt-0" cols="12" md="2">
                <v-menu
                    v-model="menu_fecha_entrega"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="fecha_entrega"
                        label="Fecha de Entrega"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="fecha_entrega"
                    @input="menu_fecha_entrega = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            
             <v-col class="pb-0 pt-0 " cols="6" md="2">
                 <v-text-field
                    v-model="filtro_pedido"
                    label="# Pedido"
                    prepend-icon="mdi-cart-arrow-down"
                    clearable
                    @click:clear="clearFiltroPedido"
                    @keydown.enter="getDataFromApi"
                ></v-text-field>

            </v-col>
            <v-col class="pb-0 pt-0" cols="12" md="4">
                <v-radio-group v-model="filtro_confirmado" row>
                    <v-radio label="Todos" value="2"></v-radio>
                    <v-radio label="Abiertos" value="0"></v-radio>
                    <v-radio label="Cerrados" value="1"></v-radio>
                </v-radio-group>

            </v-col>

             <v-col cols="6" md="2" outline>
                 <v-btn color="primary" depressed @click="getDataFromApi" icon><v-icon>mdi-filter</v-icon></v-btn>
                 <v-btn color="primary" depressed @click="getDataFromApi" icon><v-icon>mdi-refresh</v-icon></v-btn>
            </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4" class="indigo--text">
                <span v-if="pedidos"><strong>Pedidos encontrados:</strong> {{ pedidos.length }}</span>
                </v-col>
                <v-col cols="12" md="4" v-if="pedidos_notfound.length > 0" class="red--text">
                <strong> No encontrados:</strong><span v-for="id in pedidos_notfound" :key="id"> {{ id }},</span>              
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6" md="6" lg="4" v-for="item in pedidos" :key="item.id">
                    <v-lazy
                        :options="{
                        threshold: .1
                        }"
                        min-height="100"
                        transition="fade-transition"
                    >

                    <PedidoPendiente 
                        v-bind:pedido="item" 
                        :repartidores="repartidores" 
                        @updatePedido="updatePedido($event)"
                        @updateProducto="updateProducto($event)"
                        @dialogEspera="dialogEspera = $event.mostrar"
                         ></PedidoPendiente>
                    </v-lazy>
                </v-col>
            </v-row>
        </v-card-text>

        <v-dialog v-model="dialogPdf" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialogPdf = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Etiquetas</v-toolbar-title>
            </v-toolbar>

            <pdf :src="pdf_base64"></pdf>

        </v-card>
        </v-dialog>

        <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
        <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>

    </v-card>
</template>

<script>
    import moment from 'moment'
    import platform from 'platform-detect'
    import ApiService from '@/services/ApiService.js'
    import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
    import DialogEspera from '@/components/DialogEspera.vue'
    import PedidoPendiente from '@/components/PedidoPendiente.vue'
    import {
        validationMixin
    } from 'vuelidate'
    

    export default {
        name: 'Surtir',
        mixins: [validationMixin],
        components: {
            SnackbarMensajes,
            DialogEspera,
            PedidoPendiente,
        },
        data: () => ({
        pedidos_notfound: [],
          dialogPdf: false,
          pdf_base64: '',
          dialogEspera: false,
          fecha_entrega: '',
          filtro_confirmado: "2",
          filtro_pedido: "",
          menu_fecha_entrega: false,
          verRecolectados: false,
          repartidores: [],
          pedidos: [],
          sb: {
              mostrar: false,
              mensaje: '',
              color: 'success',
              timeout: 5000,
              multiline: false,
          },
        }),
        watch: {
          fecha_entrega: {
            handler(){
              this.getDataFromApi();
            }
          },
          filtro_confirmado: {
              handler(){
                  this.getDataFromApi();
              }
          }
        },
        computed: {
            canPrint() {
                return !(platform.ios && platform.pwa);

            }
        },
        methods: {
            clearFiltroPedido(){
                this.filtro_pedido = '';
                this.getDataFromApi();
            },
            printEtiquetas(){
                // solo se imprimen los confirmados
                this.dialogEspera = true;

                // solo los confirmados
                // let lospedidos = []
                // this.pedidos.forEach((item,idx,arr) => {
                //     if(item.confirmado == 1) lospedidos.push(item.id)
                // })

                if(!this.filtro_pedido) this.filtro_pedido = '';
                let filtros = {
                    fecha_entrega: this.fecha_entrega,
                    confirmado: this.filtro_confirmado,
                    idpedido: this.filtro_pedido,
                }

                
                ApiService.getEtiquetas(this.$store.getters.token, filtros)
                    .then(response => {
                        if (response.status == 200) {
                            this.dialogEspera = false;

                            var popup = window.open();   
                            var link = document.createElement('a');  
                            link.setAttribute('href', 'data:application/pdf;base64, ' + response.data );  
                            link.setAttribute('download', 'label' + Date.now() + '.pdf');  
                            popup.document.body.appendChild(link);   
                            link.click();

                        } else {
                            this.showMensaje(
                                `Error al obtener etiquetas ${response.status} - ${response.statusText} `,
                                'error', true)
                        }
                    })
                    .catch(error => {
                        if (error.response) this.showMensaje(
                            `Error al obtener etiquetas ${error.response.status} - ${error.response.statusText}`,
                            'error', true)
                        else this.showMensaje(`Error al obtener etiquetas ${error.message}`, 'error', true)
                        this.dialogEspera = false
                    })
                    .finally(() => (this.dialogEspera = false))

            },
            updateProducto(datos) {
                const data = {
                    idpedido: datos.producto_pedido.idpedido,
                    descripcion: datos.producto_pedido.descripcion,
                    codigo: datos.producto_pedido.codigo,
                    precio: datos.producto_pedido.precio,
                    surtido: datos.producto_pedido.surtido,
                    nosurtir: datos.producto_pedido.nosurtir,
                    comodin: datos.producto_pedido.comodin
                }

                this.dialogEspera = true;
                ApiService.grabaProductoPedido(this.$store.getters.token, datos.producto_pedido.id, data)
                    .then(response => {
                        // 201 => Created :: 200 => OK Updated
                        if(response.status == 200 || response.status == 201)
                        {
                            if(response.status == 201) datos.pedido.productos.push(Object.assign({}, response.data))
                            //this.showMensaje(`Producto actualizado ${datos.producto_pedido.id}`,'success',true)
                        }else{
                            this.showMensaje(`Error al grabar ${response.status} - ${response.statusText}`,'error',true)
                        }
                    })
                    .catch(error => {
                        this.dialogEspera = false;
                        if(error.response.status == 422){
                            const data_validation_errors = error.response.data.reduce( (prev, current) => prev + current.message + ';', "");
                            datos.producto_pedido.codigo = '';
                            datos.producto_pedido.surtido = false;
                            datos.producto_pedido.nosurtir = false;
                            this.showMensaje(`Dátos no válidos: ${data_validation_errors}`,'error',true);
                        }else this.showMensaje(`Error en request ${error.response.status} - ${error.response.statusText}`,'error',true)
                    })
                    .finally(() => {
                        this.dialogEspera = false
                    })

            },
            updatePedido(datos) {
                const data = {
                    confirmado: datos.estatus,
                    idrepartidor: datos.pedido.idrepartidor,
                }

                this.dialogEspera = true;
                ApiService.grabaPedido(this.$store.getters.token,data,datos.pedido.id)
                    .then(response => {
                        // 201 => Created :: 200 => OK Updated
                        if(response.status == 200 ){
                            // cambio el estatus al confirmar la respuesta de la API
                            datos.pedido.confirmado = datos.estatus;
                            this.showMensaje(`Pedido actualizado ${datos.pedido.id}`,'success',true)
                            
                            
                        }else{
                            this.showMensaje(`Error al grabar ${response.status} - ${response.statusText}`,'error',true)
                        }
                    })
                    .catch(error => {
                        this.dialogEspera = false
                        this.showMensaje(`Error al grabar ${error.response.status} - ${error.response.statusText}`,'error',true)
                    })
                    .finally(() => {
                        this.dialogEspera = false
                    })
            },
            getDataFromApi() {
                this.dialogEspera = true;
                if(!this.filtro_pedido) this.filtro_pedido = '';
                let filtros = {
                    fecha_entrega: this.fecha_entrega,
                    idpedido: this.filtro_pedido,
                    confirmado: this.filtro_confirmado
                }

                ApiService.getPedidosPendientes(this.$store.getters.token, filtros)
                    .then(response => {
                        if (response.status == 200) {
                            //console.log(response.data);
                            this.pedidos = response.data.pedidos;
                            this.pedidos_notfound = response.data.notfound;
                            this.dialogEspera = false;
                        } else {
                            this.showMensaje(
                                `Error al obtener listado ${response.status} - ${response.statusText} `,
                                'error', true)
                        }
                    })
                    .catch(error => {
                        if (error.response) this.showMensaje(
                            `Error al obtener listado ${error.response.status} - ${error.response.statusText}`,
                            'error', true)
                        else this.showMensaje(`Error al obtener listado ${error.message}`, 'error', true)
                        this.dialogEspera = false
                    })
                    .finally(() => (this.dialogEspera = false))
            },
            getRepartidores() {
                this.dialogEspera = true
                
                ApiService.getRepartidores(this.$store.getters.token)
                    .then(response => {
                        if (response.status == 200) {
                            this.repartidores = response.data;
                            this.dialogEspera = false;
                        } else {
                            this.showMensaje(
                                `Error al obtener listado ${response.status} - ${response.statusText} `,
                                'error', true)
                        }
                    })
                    .catch(error => {
                        if (error.response) this.showMensaje(
                            `Error al obtener listado ${error.response.status} - ${error.response.statusText}`,
                            'error', true)
                        else this.showMensaje(`Error al obtener listado ${error.message}`, 'error', true)
                        this.dialogEspera = false
                    })
                    .finally(() => (this.dialogEspera = false))
            },
            showMensaje(mensaje, color, multiline) {
                this.sb.mensaje = mensaje
                this.sb.multiline = multiline
                this.sb.color = color
                this.sb.mostrar = true
            },
            appendLeadingZeroes(n){
              if(n <= 9){
                return "0" + n;
              }
              return n
            },

        },
        mounted() {
          this.fecha_entrega = moment().format('YYYY-MM-DD');
          this.getRepartidores()          
        },
        created() {
            
        }
    }
</script>