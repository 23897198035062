<template>
<div>
    <v-card :color="pedido.confirmado == 1 ? 'green lighten-5' : 'grey lighten-5'">
        <v-card-title>
            Pedido {{ pedido.id }}
            <v-spacer></v-spacer>
            <!-- <v-icon left>
                mdi-cart-arrow-down
            </v-icon> -->
            {{ pedido.productos.length }} items
            <v-btn class="mx-2" small depressed fab color="primary" @click="agregarProducto" :disabled="pedido.confirmado == 1">
                <v-icon dark>mdi-cart-plus</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-subtitle>{{ pedido.vendedor_nombre }}</v-card-subtitle>
        <v-card-text class="title font-weight-bold">
            <v-list subheader color="transparent">
                <!-- <v-subheader>Productos</v-subheader> -->

                <v-list-item v-for="item in pedido.productos" :key="item.id" @click="capturaCodigo(item)">
                    <v-icon left v-if="item.surtido">mdi-checkbox-marked-outline</v-icon>
                    <v-icon left v-else-if="item.nosurtir">mdi-checkbox-blank-off-outline</v-icon>
                    <v-icon left v-else>mdi-checkbox-blank-outline</v-icon>

                    <v-list-item-content>
                        <v-list-item-title v-text="item.descripcion"></v-list-item-title>
                        <v-list-item-subtitle v-text="`${item.precio} - ${item.codigo}`"></v-list-item-subtitle>
                    </v-list-item-content>
                    
                </v-list-item>
                
            </v-list>
            <span class="caption">{{ pedido.cliente_nombre }}</span>
            <p class="overline">{{ domicilio }}</p>
            <v-select
                v-model="pedido.idrepartidor"
                :items="repartidores"
                item-text="nombre"
                item-value="idusuario"
                menu-props="auto"
                label="Selecciona al repartidor"
                hide-details
                prepend-icon="mdi-face-outline"
                single-line
                @change="updateRepartidor"
                :readonly="pedido.confirmado == 1"
                clearable
            ></v-select>

        </v-card-text>

        <v-card-actions>
            <v-chip
            small
            class="ma-2"
            :color="estatusSurtido.color"
            text-color="white"
            >
            {{ estatusSurtido.msg }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn v-if="pedido.confirmado == 0" class="mx-2" small text color="success" :disabled="puedeConfirmar" @click="setEstatusPedido(1)">
                <v-icon left small>mdi-lock-check</v-icon>Cerrar
            </v-btn>
            <v-btn v-if="pedido.confirmado == 1" class="mx-2" small text color="secondary" @click="setEstatusPedido(0)">
                <v-icon left small>mdi-lock-open-check</v-icon>Abrir
            </v-btn>
            
            <!-- <v-btn color="success" @click="pedido.recolectado_bodega = !pedido.recolectado_bodega">
                <v-icon left>mdi-target</v-icon>Encontrado
            </v-btn>
         -->
         <!-- <v-checkbox v-model="pedido.recolectado_bodega" @change="$emit('cambiarestatus')" label="Encontrado"></v-checkbox> -->
                
        </v-card-actions>
    </v-card>


    <v-dialog persistent v-model="dialogEditarProducto" scrollable max-width="450px">
        <v-card>
            <v-card-title>Capturar código</v-card-title>
            <v-card-subtitle>{{ editedItem.descripcion }} - ${{ editedItem.precio }}</v-card-subtitle>
            <v-card-text>
            
            <v-row>
                <v-col cols="12">
                <v-text-field
                    v-model.trim="$v.codigo.$model"
                    @input="$v.codigo.$touch()"
                    @blur="$v.codigo.$touch()"
                    :error-messages="productoCodigoErrors"
                    label="Código del producto"
                    hint="escribe o escanea el código completo con talla"
                    @keydown.enter="buscarPrecio"
                    persistent-hint
                    :clearable="pedido.confirmado == 0"
                    :readonly="pedido.confirmado == 1"
                ></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-text-field
                    v-model.trim="$v.precio.$model"
                    @input="$v.precio.$touch()"
                    @blur="$v.precio.$touch()"
                    :error-messages="productoPrecioErrors"
                    label="Precio del producto (incluir + $10)"
                    :readonly="pedido.confirmado == 1"
                    ></v-text-field>
                </v-col>
               
            </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn class="error"  small @click="cancelaSurtido" depressed>Cerrar</v-btn>
                <v-btn :disabled="pedido.confirmado == 1" color="secondary"  small @click="noSurtirProducto" depressed>No Surtir</v-btn>
                <!-- <v-btn v-if="editedItem.comodin==1" :disabled="pedido.confirmado == 1" color="secondary"  small @click="popProducto" depressed>Borrar</v-btn> -->
                <v-spacer></v-spacer>
                <v-btn :disabled="pedido.confirmado == 1" class="primary"  small depressed @click="surteProducto">
                    <!-- <v-icon left>mdi-checkbox-marked-circle</v-icon> -->
                    <span>Guardar</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogAgregarProducto" scrollable max-width="450px">
        <v-card>
            <v-card-text>
                <v-row>
                <v-col cols="12">
                <v-text-field
                    v-model.trim="$v.defaultProducto.codigo.$model"
                    @input="$v.defaultProducto.codigo.$touch()"
                    @blur="$v.defaultProducto.codigo.$touch()"
                    :error-messages="productoNuevoCodigoErrors"
                    label="Código del producto"
                    hint="escribe o escanea el código completo con talla"
                    persistent-hint
                    clearable
                ></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-text-field
                    v-model.trim="$v.defaultProducto.descripcion.$model"
                    @input="$v.defaultProducto.descripcion.$touch()"
                    @blur="$v.defaultProducto.descripcion.$touch()"
                    :error-messages="productoDescripcionErrors"
                    label="Nombre del producto"
                    hint="escribe completo el nombre del zapato incluye la talla"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                    v-model.trim="$v.defaultProducto.precio.$model"
                    @input="$v.defaultProducto.precio.$touch()"
                    @blur="$v.defaultProducto.precio.$touch()"
                    :error-messages="productoNuevoPrecioErrors"
                    label="Precio del producto (incluir + $10)"
                    ></v-text-field>
                </v-col>
                
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn class="error" dark small @click="dialogAgregarProducto = false" depressed>Cerrar</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="info" dark small @click="pushProducto" depressed>
                    <!-- <v-icon left>mdi-checkbox-marked-circle</v-icon> -->
                    <span>Agregar</span>
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
</div>
</template>

<script>
import ApiService from '@/services/ApiService.js'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric, minValue } from 'vuelidate/lib/validators'

export default {
    name: 'PedidoPendiente',
    mixins: [validationMixin],
    props: {
        pedido: {
            cliente_nombre: String,
            productos: Array,
            confirmado: Number,
            idrepartidor: Number,
            direccion_calle: String,
            direccion_colonia: String,
            direccion_numero: String
        },
        repartidores: Array,
    },
    data() {
        return {
            codigo: '',
            precio: 0,
            dialogEditarProducto: false,
            dialogAgregarProducto: false,
            codigoAnterior: '',
            backup: {},
            editedItem: {
                descripcion: '',
                precio: 0,
                codigo: '',
                surtido: 0,
                nosurtir: 0,
                comodin: 0,
            },
            defaultProducto: {
                id: -1,
                idpedido: -1,
                descripcion: '',
                precio: 0,
                sucursal: '',
                codigo: '',
                comodin: 0,
                surtido: 1,
                nosurtir: 0,
                entregado: false,
                vendedor_nombre: '',
            },
            sb: {
                mostrar: false,
                mensaje: '',
                color: 'success',
                timeout: 3000,
                multiline: false,
            },
        }
    },
    computed:{
        domicilio(){
            const entre = this.pedido.direccion_entrecalles.length > 0 ? ` ENTRE ${this.pedido.direccion_entrecalles}` : ''
            return `${this.pedido.direccion_calle} #${this.pedido.direccion_numero}${entre} COL. ${this.pedido.direccion_colonia}`
        },
         estatusSurtido() {
            let suma = 0;
            this.pedido.productos.forEach( item => {
                if(item.surtido) suma++
            })
            if(suma == 0) return {color: 'red', msg: 'Incompleto'}
            if(suma == this.pedido.productos.length) return {color: 'green', msg: 'Completo'}
            return {color: 'yellow darken-2', msg: 'Parcial'}
        },
        puedeConfirmar(){
            let cerrar = true;
            this.pedido.productos.forEach( item => {
                if(item.surtido == 0 && item.nosurtir == 0) cerrar = false;
            })
            return !(cerrar && !isNaN(this.pedido.idrepartidor))
        },
        productoCodigoErrors () {
            const errors = []
            if (!this.$v.codigo.$dirty) return errors
            !this.$v.codigo.minLength && errors.push('Captura el código del zapato con talla.')
            !this.$v.codigo.maxLength && errors.push('Se permiten máximo 11 caracteres')
            !this.$v.codigo.required && errors.push('Obligatorio.')
            return errors
        },
        productoPrecioErrors () {
            const errors = []
            if (!this.$v.precio.$dirty) return errors
            !this.$v.precio.numeric && errors.push('Precio inválido.')
            !this.$v.precio.minValue && errors.push('Precio debe ser mayor a $10.')
            !this.$v.precio.required && errors.push('Obligatorio.')
            return errors
        },
        productoNuevoCodigoErrors () {
            const errors = []
            if (!this.$v.defaultProducto.codigo.$dirty) return errors
            !this.$v.defaultProducto.codigo.minLength && errors.push('Captura el código del zapato con talla.')
            !this.$v.defaultProducto.codigo.maxLength && errors.push('Se permiten máximo 11 caracteres')
            !this.$v.defaultProducto.codigo.required && errors.push('Obligatorio.')
            return errors
        },
        productoNuevoPrecioErrors () {
            const errors = []
            if (!this.$v.defaultProducto.precio.$dirty) return errors
            !this.$v.defaultProducto.precio.numeric && errors.push('Precio inválido.')
            !this.$v.defaultProducto.precio.minValue && errors.push('Precio debe ser mayor a $10.')
            !this.$v.defaultProducto.precio.required && errors.push('Obligatorio.')
            return errors
        },
        productoDescripcionErrors () {
            const errors = []
            if (!this.$v.defaultProducto.descripcion.$dirty) return errors
            !this.$v.defaultProducto.descripcion.minLength && errors.push('Escribe la descripción completa incluyendo talla.')
            !this.$v.defaultProducto.descripcion.maxLength && errors.push('Se permiten máximo 250 caracteres.')
            !this.$v.defaultProducto.descripcion.required && errors.push('Obligatorio.')
            return errors
        },
       
        color() {
            return this.pedido.recolectado_bodega ? 'success' : 'orange darken-2'
        },
        iconoRecolectado(){
            if(!this.pedido) return 'mdi-checkbox-blank-outline'
            else return this.pedido.recolectado_bodega ? 'mdi-checkbox-ouline' : 'mdi-checkbox-blank-outline'
        }
    },
    validations: {
        codigo: { required, minLength: minLength(6), maxLength: maxLength(11) },
        precio: { required, numeric, minValue: minValue(10) },
        editedItem: {
            precio: { required, numeric, minValue: minValue(10) },
            codigo: { required, minLength: minLength(6), maxLength: maxLength(11) },
        },
        defaultProducto: {
            descripcion: { required, minLength: minLength(7), maxLength: maxLength(250) },
            precio: { required, numeric, minValue: minValue(10) },
            codigo: { required, minLength: minLength(6), maxLength: maxLength(11) },
        },
        
    },
    methods: {
        buscarPrecio() {
            if(this.codigo.length == 0) return;
            this.$emit('dialogEspera',{ mostrar: true });
            const claveproducto = this.codigo.substring(0,6).toUpperCase();
            console.log(claveproducto);

            ApiService.getPrecioProducto(this.$store.getters.token, claveproducto)
                .then(response => {
                    if (response.status == 200) {
                        console.log(response.data);
                        this.precio = response.data[0].precio;
                        this.$emit('dialogEspera',{ mostrar: false });
                    } else {
                        this.showMensaje(
                            `Error al obtener listado ${response.status} - ${response.statusText} `,
                            'error', true)
                    }
                })
                .catch(error => {
                    if (error.response) this.showMensaje(
                        `Error al obtener listado ${error.response.status} - ${error.response.statusText}`,
                        'error', true)
                    else this.showMensaje(`Error al obtener listado ${error.message}`, 'error', true)
                    this.$emit('dialogEspera',{ mostrar: false });
                })
                .finally(() => (this.$emit('dialogEspera',{ mostrar: false })))
        },
        updateRepartidor(){
            if(typeof this.pedido.idrepartidor === 'undefined') this.pedido.idrepartidor = null;
            this.$emit('updatePedido',{ pedido: this.pedido, estatus: this.pedido.confirmado })
        },
        setEstatusPedido(estatus){
            this.$emit('updatePedido',{ pedido: this.pedido, estatus: estatus })
        },
        cancelaSurtido(){
            this.editedItem.codigo = this.backup.codigo
            this.editedItem.precio = this.backup.precio
            this.dialogEditarProducto = false
        },
        capturaCodigo(item){
            this.backup = Object.assign({}, item)
            this.codigo = item.codigo;
            this.precio = item.precio;
            this.editedItem = item
            this.$v.editedItem.$reset
            this.dialogEditarProducto = true
        },
        noSurtirProducto(){
            this.editedItem.codigo = ''
            this.editedItem.nosurtir = 1
            this.editedItem.surtido = 0
            this.dialogEditarProducto = false
            this.$emit('updateProducto',{ pedido: this.pedido, producto_pedido: this.editedItem })
        },
        surteProducto(){
            this.$v.codigo.$touch();
            this.$v.precio.$touch();
            if(this.$v.codigo.$invalid){
                this.showMensaje(`Debes capturar el código del producto`,'error',true)
                return false;
            }

            if(this.$v.precio.$invalid){
                this.showMensaje(`Debes capturar el precio del producto`,'error',true)
                return false;
            }


            this.editedItem.codigo = this.codigo;
            this.editedItem.precio = this.precio;
            if(!this.editedItem.codigo) this.editedItem.codigo = ''

            this.$v.editedItem.$touch()
            if(this.$v.editedItem.$invalid) return false;

            this.editedItem.surtido = this.editedItem.codigo.length > 0 ? 1 : 0
            this.editedItem.nosurtir = 0
            this.dialogEditarProducto = false
            this.$emit('updateProducto',{ pedido: this.pedido, producto_pedido: this.editedItem })
        },
        agregarProducto(){
            this.defaultProducto = Object.assign({}, {
                id: -1,
                idpedido: this.pedido.id,
                descripcion: '',
                precio: 0,
                sucursal: '',
                codigo: '',
                comodin: 1,
                surtido: 1,
                nosurtir: 0,
                entregado: false,
            })
            this.dialogAgregarProducto = true
        },
        pushProducto(){
            this.$v.defaultProducto.$touch()
            if(!this.$v.defaultProducto.$invalid){
                this.$emit('updateProducto', { pedido: this.pedido, producto_pedido: this.defaultProducto })
                this.dialogAgregarProducto = false
            }
        },
        popProducto(){
            let idx = this.pedido.productos.indexOf(this.editedItem)
            this.pedido.productos.splice(idx, 1)
            this.dialogEditarProducto = false
        },
        showMensaje(mensaje,color,multiline) {
            this.sb.mensaje = mensaje
            this.sb.multiline = multiline
            this.sb.color = color
            this.sb.mostrar = true
        },
    }
}
</script>

<style>

</style>